$(document).foundation();

$(function () {

    // //Typed.js, more info: https://github.com/mattboldt/typed.js/
    // var options = {
    //     strings: ["<i>First</i> sentence.", "Tom is dik.", "Joris moet kotsen"],
    //     typeSpeed: 40
    // }
    // var typed = new Typed(".typed", options);

    // //wow.js, more info: https://github.com/matthieua/WOW
    // var wow = new WOW(
    //     {
    //         boxClass:     'wow',      // animated element css class (default is wow)
    //         animateClass: 'animated', // animation css class (default is animated)
    //         offset:       300,          // distance to the element when triggering the animation (default is 0)
    //         mobile:       true,       // trigger animations on mobile devices (default is true)
    //         live:         true,       // act on asynchronously loaded content (default is true)
    //         callback:     function(box) {
    //             // the callback is fired every time an animation is started
    //             // the argument that is passed in is the DOM node being animated
    //         },
    //         scrollContainer: null // optional scroll container selector, otherwise use window
    //     }
    // );
    // wow.init();

    $('.faq__title').on('click', function () {
        let question = $(this).closest('.faqquestion');
        question.find('.answer').slideToggle();
        question.toggleClass('faqquestion--open');
    })

    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
            breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    });

    $('.filter__button__aantal span').click(function(){
        $('html').toggleClass('filter__open');
        $('.filter__shadow').fadeToggle(300)
    });

    $('.filter__shadow').click(function(){
        $('html').toggleClass('filter__open');
        $('.filter__shadow').fadeToggle(300)
        // document.ontouchmove = function(event){
        //     event.preventDefault();
        // }
    });

    $('.filter__button').click(function(){
        $('html').toggleClass('filter__open');
        $('.filter__shadow').fadeToggle(300)
    });

    $('.filter__button__close').click(function(){
        $('html').toggleClass('filter__open');
        $('.filter__shadow').fadeToggle(300)
    });

    $('.filterblock__title').click(function(){
        var parent = $(this).parent()
        parent.find('.filterblock__list').slideToggle(300)
        $(this).parent().toggleClass('filterblock__open')
    });

    $('.blockfilter__item__inner').click(function(){
        $(this).toggleClass('blockfilter__item__inner__active');
    });

    $(function() {
        $('.match-height').matchHeight();
    });

    $(function() {
        $('.blockfilter__item__inner').matchHeight();
    });

    $(function() {
        $('.cathome__item__title').matchHeight();
    });

    $(function() {
        $('.cathome__item__inner').matchHeight();
    });

    $(function() {
        $('.productlist__item__inner').matchHeight();
    });

    $(function() {
        $('.productlist__item__title').matchHeight();
    });



    var getText = $('.step__item__current .step__title').text();
    $('.step__mobile__name').html(getText);

    $('.content__image--right').each(function() {
        var imgSrc = $(this).attr('src');
        $(this).parent().css({'background-image': 'url('+imgSrc+')'});
        $(this).remove();
    });

    
    // voorkomen dat dropdown closed bij klik in dropdown //
    $('.dropdown-menu__insideclick .dropdown-menu').on('click', function(event){
        var events = $._data(document, 'events') || {};
        events = events.click || [];
        for(var i = 0; i < events.length; i++) {
            if(events[i].selector) {
                if($(event.target).is(events[i].selector)) {
                    events[i].handler.call(event.target, event);
                }
                $(event.target).parents(events[i].selector).each(function(){
                    events[i].handler.call(this, event);
                });
            }
        }
        event.stopPropagation();
    });
    // End //


    $('.footer--fixed').each(function() {
        footerHeight = $("footer").height();
        $(".footer--fixed .main").css({'margin-bottom': footerHeight});
    });

    $(document).ready(function() {
        $("a").each(function(i) {
            $(this).attr('id', ("go-a-" + (i+1)));
        });
    });

    $(document).ready(function() {
        $("button").each(function(i) {
            $(this).attr('id', ("go-button-" + (i+1)));
        });
    });

    jQuery(document).ready(function() {
        jQuery('.timeline-item:even').addClass("").viewportChecker({
            classToAdd: 'timeline-animation animated bounceInLeft',
            offset: 240
        });
        jQuery('.timeline-item:odd').addClass("").viewportChecker({
            classToAdd: 'timeline-animation animated bounceInRight',
            offset: 240
        });
    });

    $(".faq__item__btn").click(function () {
        $(this).parent().find('.faq__item__answer').toggleClass("faq__item__answer--open");
        $(this).toggleClass("faq__item__btn--open");
    });

    $('.content__hire__right__outer').theiaStickySidebar({
        additionalMarginTop: 115
    });

    $(".fdatepicker").fdatepicker({
        language: 'nl',
        format: 'dd-mm-yyyy',
    });

    $(".fdatepicker-inline").fdatepicker({
        language: 'nl',
        format: 'dd-mm-yyyy',
        isInline: true,
        startDate: 'now'
    });
    
    $(".fdatepicker-no-future").fdatepicker({
        language: 'nl',
        format: 'dd-mm-yyyy',
        endDate: 'now'
    });

    $('.timepicker').fdatepicker({
       format: 'hh:ii',
       language: 'nl',
       disableDblClickSelection: false,
       pickTime: true
   });

   // $(".timepicker").click(function () {
   //     $('.datepicker-days').hide();
   //     $('.datepicker-hours').show();
   // });

    $(".disable-scroll").click(function () {
        $('.disable-scroll').fadeOut();
    });

    $(".language__selector__current").click(function () {
        $('.language__selector__all').fadeToggle();
    });

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll > 70) {
            $(".header__top").addClass("header-fix");
        }
        else {
            $(".header__top").removeClass("header-fix");
        }
    });


    $('.scrollto a[href*="#"]:not([href="#"])').on('click',function(e) {
    	e.preventDefault();
    	var offset = 20;
    	var target = this.hash;
    	if ($(this).data('offset') != undefined) offset = $(this).data('offset');
    	$('html, body').stop().animate({
    		'scrollTop': $(target).offset().top - offset
    	}, 800, 'swing', function() {
    		// window.location.hash = target;
        });
    });


    // scroll body to 0px on click
    $('#back-totop').click(function () {
        $('body,html').animate({scrollTop: 0}, 800);
        return false;
    });

    $('.fancybox').fancybox({
        beforeLoad: function (data) {
            $('.debugmsgs').remove();
        },
        helpers: {
            overlay: {
                locked: true
            }
        }
    });

    $('.content-fancybox-gallery .fancybox').each(function () {
        $(this).attr('data-fancybox-group', 'data-fancybox-group');
    });

    $('.fancybox-ajax').fancybox({
        type: 'ajax',
        beforeLoad: function (data) {
            $('.debugmsgs').remove();
        },
        helpers: {
            overlay: {
                locked: false
            }
        }
    });

    $('.fancybox-iframe2').fancybox({
        'type': 'iframe',
        'titleShow': false,
        'transitionIn': 'elastic',
        'transitionOut': 'elastic',
        'padding': 0,
        'maxWidth': 800,
        'maxHeight': 450,
        'fitToView': true,
        'autoScale': true,
        helpers: {
            overlay: {
                locked: false
            }
        }
    });

    if (window.matchMedia('(min-width: 1024px)').matches) {
        var e = document.getElementsByClassName("phone");
        for (i = 0; i < e.length; i++)
        e[i].setAttribute("x-ms-format-detection", "none");
    }


    ajaxFilter();

    // popup warning
    $(".warning__close").click(function () {
        $(".warning").css("display","none");
    });
});

function ajaxFilter()
{
    $('.ajax-filter').each(function(){
        var form = $(this);
        form.append('<input type="hidden" name="action" value="Filter" />');
        form.append('<input type="hidden" name="koppel" value="' + form.attr('data-koppel') + '" />');
        $(this).find(':input[type="checkbox"]').change(function(){
            $.fancybox.showLoading();
            $.ajax({
                url: window.location.href,
                data: form.serialize(),
                type: 'post',
                success: function(data)
                {
                    $('.ajax-filter').replaceWith(data);
                    new Foundation.Accordion($('.accordion'));
                    $.fancybox.hideLoading();
                    ajaxFilter();
                }
            });
        })
    });
}

$(document).ready(function() {
    $('.reference__slider').slick({
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: true,
        variableWidth: true
    });
});
