function addNextBtn() {
    $('.embedded-daterangepicker[data-single] .drp-calendar.left').find('thead tr:first-child th:last-child').addClass('next available').html('<span></span>');
}

function setSinglePicker() {
    var picker = $('.embedded-daterangepicker[data-single]');

    // prevent hide after range selection
    picker.data('daterangepicker').hide = function () {
        addNextBtn();
    };
    // show picker on load
    picker.data('daterangepicker').show();

    picker.data('daterangepicker').updateView = function() {
        if (this.timePicker) {
            this.renderTimePicker('left');
            this.renderTimePicker('right');
            if (!this.endDate) {
                this.container.find('.right .calendar-time select').attr('disabled', 'disabled').addClass('disabled');
            } else {
                this.container.find('.right .calendar-time select').removeAttr('disabled').removeClass('disabled');
            }
        }
        if (this.endDate)
            this.container.find('.drp-selected').html(this.startDate.format(this.locale.format) + this.locale.separator + this.endDate.format(this.locale.format));
        this.updateMonthsInView();
        this.updateCalendars();
        this.updateFormInputs();

        addNextBtn();
    };


    $(document).on('click', '*', function() {
        addNextBtn();
    });
    addNextBtn();
}